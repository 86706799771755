import { gql } from "@apollo/client"
import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Modal } from "components"
import Typography from "components/common/Typography"
import { compose, withHooks, withStores, withTranslation } from "enhancers"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import Theme from "theme/custom"
import { paths, toCurrency } from "utils/helper"
import { Insurance, InsuranceSelectorComponent, InsuranceSelectorProps } from "./InsuranceSelector"
import { ResultScore } from "./ResultScore"

const API = {
  GET_MASTER_BENEFIT_INSURANCE: gql`
    query GET_MASTER_BENEFIT_INSURANCE($id: String!) {
      masterBenefitInsuranceInEdit(id: $id) {
        id
        type
        masterBenefitInsurancePackages {
          id
          requiredPoints
          masterInsurancePackage {
            id
            nameTh
            nameEn
            packagePricingType
            insurancePackagesInsurancePlans {
              id
              insurance {
                id
                nameTh
                nameEn
                premium
                remarkTh
                remarkEn
                description
              }
            }
          }
        }
      }
    }
  `,
  validatePoints: gql`
    mutation validatePoints(
      $benefitInsuranceId: String
      $masterBenefitInsurancePlan: JSON
      $id: String
      $points: Float
      $selectedBenefitInsurancePackage: JSON
    ) {
      validateAttendeePoints(
        input: {
          benefitInsuranceId: $benefitInsuranceId
          masterBenefitInsurancePlan: $masterBenefitInsurancePlan
          id: $id
          points: $points
          selectedBenefitInsurancePackage: $selectedBenefitInsurancePackage
        }
      ) {
        error
        availablePoint
        usingPoint
        familyPoint
        totalPoint
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
    selectedPreviousBenefitInsuranceStore: stores.previousBenefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<InsuranceSelectorProps, "t"> => {
      const {
        useState,
        useCallback,
        useParams,
        useQuery,
        useMemo,
        useEffect,
        useDataTranslation,
        useUrlParam,
        useMutation,
      } = hooks
      const { t, selectedBenefitInsurancePackage, selectedPreviousBenefitInsuranceStore } = props
      const { id } = useParams()
      const { masterInsurancePackageId, benefitInsuranceId } = useUrlParam()
      const [insurance, setInsurance] = useState(null)
      const { data: masterBenefitInsuranceData } = useQuery(API.GET_MASTER_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })
      const [validateAttendeePoint, { data: validatePoint }] = useMutation(API.validatePoints, {
        onCompleted: (data: any) => {
          const error = data.validateAttendeePoints.error
          const availablePoint = data.validateAttendeePoints.availablePoint
          const usingPoint = data.validateAttendeePoints.usingPoint
          const familyPoint = data.validateAttendeePoints.familyPoint
          const totalPoint = data.validateAttendeePoints.totalPoint
          if (error) {
            Modal.open({
              children: (
                <ResultScore
                  typeEdit={".ownType"}
                  availablePoint={availablePoint}
                  usingPoint={usingPoint}
                  familyPoint={familyPoint}
                  totalPoint={totalPoint}
                />
              ),
              okButtonVariant: "contained",
              okButtonLabel: "เลือกใหม่อีกครั้ง",
              cancelButtonVariant: "outlined",
              cancelButtonLabel: "ยกเลิกและไม่บันทึก",
              disableBackdropClick: true,
              onOk: () => {
                benefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedPreviousBenefitInsuranceStore)
                Modal.close()
              },
              onCancel: () => {
                benefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedPreviousBenefitInsuranceStore)
                Modal.close()
                handleClickBack()
              },
            })
          } else {
            Modal.open({
              children: (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ flexDirection: "column" }}>
                  <EditLineIcon
                    width={50}
                    height={50}
                    style={{ marginTop: 0, marginBottom: "32px" }}
                    fill={Theme.colors["Primary/Primary Text"]}
                  />
                  <Typography variant="Header/16">{t(".selectInsurancePlan")}</Typography>
                  <br />
                  <Typography variant="Body/14">{t(".needToSelect", { name: insurance.title })}</Typography>
                  <Box display="flex" alignItems="center" style={{ marginBottom: "16px" }}>
                    <PointCoinIcon style={{ marginRight: "8px" }} />
                    <Typography variant="Header/20">{`${toCurrency(insurance.point, { minimumFractionDigits: 0 })} ${t(
                      ".point",
                    )}`}</Typography>
                  </Box>
                </Box>
              ),
              okButtonVariant: "contained",
              okButtonLabel: "ตกลง",
              cancelButtonVariant: "outlined",
              cancelButtonLabel: "ยกเลิก",
              disableBackdropClick: true,
              onOk: () => {
                Modal.close()
                handleClickBack()
              },
              onCancel: () => {
                benefitInsuranceStore.setSelectedBenefitInsurancePackage(selectedPreviousBenefitInsuranceStore)
                Modal.close()
              },
            })
          }
        },
      })

      const masterBenefitInsuranceDataTranslated = useDataTranslation(masterBenefitInsuranceData)

      const insurancePackages = useMemo(() => {
        if (masterBenefitInsuranceDataTranslated) {
          const response = masterBenefitInsuranceDataTranslated?.masterBenefitInsuranceInEdit?.masterBenefitInsurancePackages.map(
            (masterBenefitInsurancePackage: any) => {
              let description = ""
              for (const insurancePackagesInsurancePlan of masterBenefitInsurancePackage.masterInsurancePackage
                .insurancePackagesInsurancePlans) {
                description += insurancePackagesInsurancePlan.insurance.description
              }
              return {
                id: masterBenefitInsurancePackage.masterInsurancePackage.id,
                title: masterBenefitInsurancePackage.masterInsurancePackage.name,
                point: masterBenefitInsurancePackage.requiredPoints,
                description,
              }
            },
          )
          return response
        }
        return []
      }, [masterBenefitInsuranceDataTranslated])

      const [selected, setSelected] = useState("")

      const indexOfSelectedInsurance = insurancePackages.findIndex((element: any) => element.id === selected)

      // MOCK
      const insuranceType = {
        title: t(".package"),
      }
      const handleClickBack = useCallback(() => {
        paths.benefitPackageManagementPath(id).push()
      }, [id])

      const showModal = useCallback(
        (selectedPackage: any) => {
          console.log(benefitInsuranceId, "benefitInsuranceId")
          validateAttendeePoint({
            variables: {
              benefitInsuranceId: benefitInsuranceId,
              masterBenefitInsurancePlan: {},
              id,
              points: selectedPackage.requiredPoints,
              selectedBenefitInsurancePackage: selectedPackage ?? {},
            },
          })
        },
        [benefitInsuranceId, id, validateAttendeePoint],
      )

      const handleSelect = useCallback(
        async (insurance: Insurance) => {
          setSelected(insurance.id)
          setInsurance(insurance)
          if (masterBenefitInsuranceData) {
            const { masterBenefitInsuranceInEdit } = masterBenefitInsuranceData
            const { masterBenefitInsurancePackages } = masterBenefitInsuranceInEdit
            const selectedPackage = masterBenefitInsurancePackages.find(
              (item: any) => item.masterInsurancePackage.id === insurance.id,
            )
            console.log(selectedPackage)
            if (selectedPackage) {
              await benefitInsuranceStore.setSelectedBenefitInsurancePackage({
                id: selectedPackage.masterInsurancePackage.id,
                masterInsurancePackage: selectedPackage.masterInsurancePackage,
                point: selectedPackage.requiredPoints,
              })
              // handleClickBack()
              console.log(selectedBenefitInsurancePackage, "selectedBenefitInsurancePackage")
              showModal(selectedPackage)
            }
          }
        },
        [masterBenefitInsuranceData, selectedBenefitInsurancePackage, showModal],
      )

      useEffect(() => {
        if (selectedBenefitInsurancePackage) {
          setSelected(selectedBenefitInsurancePackage.masterInsurancePackage.id)
        } else if (masterBenefitInsuranceData) {
          const { masterBenefitInsurancePackages } = masterBenefitInsuranceData.masterBenefitInsuranceInEdit

          const selectedPackage = masterBenefitInsurancePackages.find(
            (item: any) => item.masterInsurancePackage.id === masterInsurancePackageId,
          )
          if (selectedPackage) {
            benefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: selectedPackage.masterInsurancePackage.id,
              masterInsurancePackage: selectedPackage.masterInsurancePackage,
              point: selectedPackage.requiredPoints,
            })
          }
        }
      }, [selectedBenefitInsurancePackage, masterInsurancePackageId, masterBenefitInsuranceData])

      return {
        indexOfSelectedInsurance,
        handleClickBack,
        handleSelect,
        insurances: insurancePackages,
        selected,
        header: insuranceType.title,
      }
    },
  ),
)

export const PackageSelectorPage = enhancer(InsuranceSelectorComponent)
