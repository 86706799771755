const en = {
  translation: {
    client: {
      api: {
        modal401Title: "Login expired",
        modal401Children: "Login expired Please log in again.",
        modal401OkButtonLabel: "Login",
        modal403Title: "Error",
        modal403Children: "Your user role doesn't have access to this section. Please log in again.",
        modal403OkButtonLabel: "Login",
        modal500Title: "Error",
        modal500OkButtonLabel: "Ok",
        modal458Title: "Error",
        modal458Children: "Insurance cannot be modified.",
        modal458Children2: "Because it is now outside the period that is open for insurance modifications",
        modal458Children3: " The last edited data will not be saved.",
        modal458OkButtonLabel: "Ok",
      },
      pages: {
        auth: {
          SignIn: {
            title: "Enter your registered email address to receive a 6-digit OTP for logging in.",
            requestOtp: "Send OTP",
            refCode: "Ref Code: {{refCode}}",
            retryOtpIn: "ขอ OTP อีกครั้งในอีก {{retryTime}} วินาที",
            retryOtpAgain: "Send OTP again {{retryTime}}",
            login: "Sign In",
            logoDescription: "Choose the insurance that is right for you.",
            email: "Email",
            aboutCompany:
              "If you need help, you can contact {{companyName}} at Call {{companyPhoneNumber}} or email {{companyEmail}}",
            enterOtp: "Enter OTP code",
            enterTitle: "Enter OTP from email {{email}} (The code is valid for 5 minutes)",
            noOtp: "Didn't receive OTP code?",
            otp: "OTP",
            otpPlaceholder: "6 digits code",
            emailPlaceholder: "For example, xxx@companyname.com.",
            language: "Language",
            languageOption: "Language Options",
            thai: "Thai",
            english: "English",
            dataSave: "Data saved.",
          },
        },
        main: {
          "about-belive": {
            index: {
              aboutBelive: "About Believe",
              contactInformation: "Contact Information",
              phone: "Phone number",
              email: "Email",
            },
          },
          account: {
            index: {
              title: "Account",
              profile: "Profile",
              setting: "Setting",
              aboutBelive: "About Believe",
              signOut: "Sign out",
              viewProfile: "View Profile",
              cancel: "Cancel",
              confirm: "Confirm",
              logoutDescrip: "Do you want to log out and go back to the homepage?",
            },
          },
          benefit: {
            index: {
              title: "Benefit of  {{year}}",
              canChange: "Adjust benefits for {{year}} year today.",
              upToDate: "Up to {{date}}",
              pointOfYear: "point of {{year}}",
              point: "Point",
            },
            Footer: {
              title: "Adjustable benefits",
              ownInsurance: "Own insurance",
              next: "Next",
            },
            detail: {
              title: "ฺBenefit in {{year}}",
              header: "Benefit of {{year}} an chosen",
              description: "in {{year}} you will receive the benefits below. But you can still edit it.",
              yourInsurance: "own insurance",
              age: "{{age}} year old",
              insuranceDetail: "Insurance details",
              edit: "Edit",
              notConfirm: "Insurance selection has not been confirmed.",
              notConfirmYet: "You haven't confirmed insurance fixes.",
              ifYouOut: " If you leave this page, you will not be able to recover the edits you have made.",
              close: "Close",
              exitNotSave: "Exit and do not save",
              upload: "Please upload file.",
              errorDownloadFile: "Unable to download file",
            },
            InsuranceSelector: {
              title: "Select your plan",
              point: "Point",
              selectInsurance: "Select insurance plan",
              benefit: "Benefits and Protection (บาท)",
              insuranceYourSelect: "Your chosen insurance plan",
              selectInsurancePlan: "Choose an insurance plan",
              needToSelect: `Need to choose plan "{{name}}"`,
              package: "Package Insurance",
              editData: "มีการแก้ไขข้อมูล",
              editDataList: "คุณมีการแก้ไช้ข้อมูลที่ยังไม่ได้บันทึกดังนี้",
              totalPoint: "สรุปคะแนน",
              ownType: "ประกันของตัวเอง",
              familyType: "ประกันของครอบครัว",
              availablePoint: "คะแนนที่มีอยู่",
              usingPoint: "ประกันของตัวเอง",
              familyPoint: "ประกันของครอบครัว",
              remainingPoint: "คะแนนคงเหลือ",
            },
            FooterSummary: {
              summaryPoint: "Summary",
              availablePoint: "Available Point",
              usingPoint: "Use",
              familyPoint: "ประกันของครอบครัว",
              remainingPoint: "Remain",
              confirm: "Confirm",
              titleError: "Unable to choose this insurance",
              messageError:
                "Because your existing points are not enough. Please edit the selected insurance item again.",
              confirmError: "Ok",
              clound: "Send all selection data",
              modalForceCompleteText1: "All selections",
              modalForceCompleteText2:
                "Want to confirm the selected insurance plan information immediately Without waiting for the end of the insurance selection period",
              modalForceCompleteText3: "Confirmed insurance information cannot be edited again.",
            },
            view: {
              title: "My {{year}} company welfare",
              selected: "Selected year {{year}} welfare",
              view: "View details and coverage of your {{year}} benefits.",
              ownInsurance: "Own Insurance",
              years: "({{year}} years old)",
              insuranceDetail: "Insurance Details",
              viewDetail: "View Details",
              custom: "Insurance",
              package: "Package Insurance",
            },
            viewDetail: {
              title: "Own Insurances",
              detail: "Benefits and Coverage (Baht)",
              select: "Your chosen insurances",
              requirePoint: "-{{point}} points",
              package: "Package Insurance",
            },
            Consent: {
              header: "Consent",
              title:
                "from choosing to adjust the welfare of their own insurance plan I hereby confirm and agree to the terms and conditions as follows",
              footer:
                "By pressing the “Confirm” button, I confirm that I have read, understood and accepted the Terms and Conditions as stated above",
              acceptConsent: "Accept the terms and conditions",
              cancel: "Cancel",
              accept: "Confirm",
            },
          },
          home: {
            index: {
              title: "Flex",
            },
            InsuranceItem: {
              warning: {
                header: "Benefits can be adjusted until {{date}}",
                title: "Adjust benefits today",
                description: "Adjust benefits to suit you and meet your needs beyond basic benefits.",
                buttonTxt: "Benefits Management Year {{year}}",
              },
              info: {
                header: "Effective until {{date}}",
                title: "Benefit in {{year}}",
                description: "See your details and coverage of benefit in {{year}} ",
                buttonTxt: "Benefit year {{year}}",
              },
            },
            EmptyInsuranceItem: {
              title: "Please wait for further updates.",
              description: "There is no welfare cycle that can be selected.",
              contact: "For more information, please contact",
              phone: "Phone number",
              email: "Email",
            },
          },
          profile: {
            index: {
              title: "Profile",
              age: "Age {{age}} years old",
              contactInformation: "Contact Information",
              email: "Email",
            },
          },
          setting: {
            index: {
              title: "Setting",
              language: "Language",
              currentLanguage: "English",
              displaySetting: "Display setting",
              languageOption: "Language Options",
              thai: "Thai",
              english: "English",
              dataSave: "Data saved.",
            },
          },
        },
      },
      components: {
        common: {
          PopMenu: {
            profile: "Profile",
            logout: "Logout",
          },
          Table: {
            dataNotFound: "ไม่พบข้อมูลที่ค้นหา กรุณาตรวจสอบค่าในตัวกรอง",
          },
          Footer: {
            home: "Home",
            account: "Account",
          },
        },
      },
      consentRule: {
        1: "I have read Understand and accept the terms and conditions. Procedures as well as details of the welfare plans of their own insurance plans that the company provided and communicated to me by which I have the right to participate in the said welfare program",
        2: "By pressing the  “Confirm” button, I wish to use the benefit I have selected. Selected welfare is at my own discretion, consideration and choice.",
        3: "I agree to be responsible for any excess costs incurred by choosing an insurance plan that is higher than the original (default) entitlement plan and allow this cost to be deducted through the payroll system.",
        4: "When the registration is completed and the company have accepted such registration I acknowledge and agree that I cannot make any changes to the benefits I have chosen. Until a new selection and registration period as specified by the Company",
        5: "If I have not made a benefit selection during the registration period set by the company I acknowledge and agree to the terms of the original (default) group insurance benefits in accordance with the policies provided by the Company.",
        6: "I understand and agree that certain benefit selections will be recorded as income. in order to calculate personal income tax in the payroll period according to the tax rate determined by the Revenue Department",
        7: "I understand and accept that having the right to welfare benefits of the company will end on the date of termination of employment",
        8: "Insurance benefits are in accordance with the terms and conditions of the insurance company. In the event of a conflict between the terms and conditions of the insurance Please mainly refer to the terms and conditions of the insurance company.",
      },
    },
  },
}

export default en
